import React, { useState } from 'react';
import { IonLabel, IonIcon, IonFab, IonFabButton, IonSlides, IonSlide } from '@ionic/react';
import './MPContainer.css';
import { Plugins } from '@capacitor/core';
import {swipeSlider} from './utils'
import {isIOS} from 'react-device-detect';

interface ContainerProps { }

let userDetails : any;
let initted = false;

const slideOpts = {
  initialSlide: 0,
  speed: 400
};

let slider : any;

const ProfileContainer: React.FC<ContainerProps> = () => {

  const { Storage } = Plugins;

  const [wideScreenMode, setWideScreenMode] = useState(false);

  const [userName, setUserName] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userAbout, setUserAbout] = useState("");
  const [pictures, setPictures] = useState([]);

  if (!initted) {
    initted = true;
    
    if (window.innerWidth > 800) {
      setWideScreenMode(true);
    }

    Storage.get({key:"userDetails"}).then((data: any) => {
      if (data.value) {
        userDetails = JSON.parse(data.value);
        setUserName(userDetails.name);

        var diff_ms = Date.now() - new Date(userDetails.dob).getTime();
        var age_dt = new Date(diff_ms); 
        setUserAge(Math.abs(age_dt.getUTCFullYear() - 1970) + "");
        if (userDetails.city && userDetails.city.length > 1)
        setUserCity("Lives in " + userDetails.city);
        setUserAbout(userDetails.about);
        setPictures(userDetails.pictures);
      }
    });
  }

  
  let pictureIdx = 0; // scroll through pictures in wide screen mode

  return (
    <div className={"mpcontainer maincontainer" + (isIOS? " " : "")}>
      {wideScreenMode? (
        <div>
          <br/><br/>
          {pictures.length > 0? (
          <img onClick={(e)=>{
            if (pictures.length > (pictureIdx + 1)) {
              pictureIdx++;
            } else {
              pictureIdx = 0;
            }
            (e.target as HTMLImageElement).src = "https://gowith.s3.us-east-2.amazonaws.com/users/" + userDetails.id + "_" + pictures[pictureIdx] + ".jpeg";
          }}
           src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + userDetails.id + "_" + pictures[pictureIdx] + ".jpeg"} className="profilePhotoWide cursorHand"/>
        ):""}
        {pictures.length == 0? (
         <img src={"assets/icon/defaultuserphoto.svg"} className="profilePhotoWide"/>
        ):("")}
        </div>
      ):(
        <IonSlides pager={true} options={slideOpts} ref={e => slider= e} onClick={(e:any)=>{swipeSlider(e, slider);}}>
        {pictures.map(picture =>
        <IonSlide>
          <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + userDetails.id + "_" + picture + ".jpeg"} className={wideScreenMode? " profilePhotoWide" : "profilePhoto"}/>
        </IonSlide>
        )}
        {pictures.length == 0? (
         <IonSlide>
         <img src={"assets/icon/defaultuserphoto.svg"} className="profilePhoto"/>
       </IonSlide>
        ):("")}
      </IonSlides>
      )}
    
    <IonFab vertical="bottom" horizontal="end" slot="fixed" className="editiconfab">
      <IonFabButton href="Setup">
        <IonIcon  className="editicon" icon="assets/icon/editinfo.svg" />
      </IonFabButton>
    </IonFab>
    <br/><br/>
    <div className="profileDetails">
  <IonLabel className="profileName"><b>{userName}</b> {userAge == "NaN"? "": ", " + userAge}</IonLabel><br/>
  <IonLabel className="profileCity">{userCity}</IonLabel>
    
    <br/><br/>
    <div className={"profileBio" + (userAbout && /[א-ת].+/.test(userAbout.trim())? " rtl":"")} dir="">
    <span className="preLine">{userAbout}</span>
    </div>
    <br/><br/><br/>
    </div>

    
    
    </div>
  );
};

export default ProfileContainer;
