import React  from 'react';
import { Plugins } from '@capacitor/core';


import './MPContainer.css';

interface ContainerProps { }

const SignInContainer: React.FC<ContainerProps> = () => {

  const { Storage } = Plugins;

  // Storage.remove({key:'userDetails'});
  Storage.clear();
  document.location.href = "/Hello";

  return (
    <div className="mpcontainer">
     signed out
    </div>
    
  );
};


export default SignInContainer;
