import React, { useState }  from 'react';
import FacebookLogin from 'react-facebook-login';
import './MPContainer.css';
import {host} from '../App.config.json';
import { Plugins } from '@capacitor/core';
import { IonButton } from '@ionic/react';
import {isMobile} from 'react-device-detect';
import log from 'loglevel';
import {loglevelServerSend} from './utils';

interface ContainerProps { }


let initted = false;
const FACEBOOK_PERMISSIONS = ['public_profile', 'email', 'user_photos'];

const FaceBookSignInUpContainer: React.FC<ContainerProps> = () => {

    const { Storage } = Plugins;
  
    const responseFacebook = (response : any) => {
      facebookSignInCB(response.accessToken, response.id, response.name, response.email);
    }

    const [isWeb, setIsWeb] = useState(false);
    const [isRetryAsWeb, setIsRetryAsWeb] = useState(false);
  
    const init = async () => {
        if (!initted) {
            initted = true;

            loglevelServerSend(log,{});

            const params = new URLSearchParams(document.location.search);
            let codeParam = params.get("code");
            if (codeParam) {
                setTimeout(() => {
                facebookSignIn();
                }, 1000);
                
            }
                  
        // if (isMobile) setis
        // try {
        //   // await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
        // } catch (e) {
        //   setIsWeb(true);
        // }
        }
    }
    init();

    const facebookSignIn = async () => {
    
        try {
        let result = await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });//.then((result: any)  => { // FacebookLoginResponse
    
          if (result && result.accessToken) {
            // Login successful.
            facebookSignInCB(result.accessToken.token, result.accessToken.userId);
          } else {
            // Cancelled by user.
            log.error("Error signing in with Facebook (Cancelled by user)");
            alert("Error signing in with Facebook");
          }
        // });
        } catch (ex) {
          setIsRetryAsWeb(true);
          setIsWeb(true);
          setTimeout(() => {
            let btns = document.getElementsByClassName("kep-login-facebook");
            if (btns) (btns[0] as HTMLButtonElement).click();
          }, 1000);
        }
      }
    
      const facebookSignInCB = (token: string, userId: string, name?: string, email?: string) => {
    
      
          log.info('Facebook access token is ' + token + ' and userId is ' + userId + '}');
            // alert("sign in ok, token: " + token + ", userId: " + result.accessToken.userId);
            
            // try login with facebook userid
            fetch(host + "/signinFacebook", {
              method: 'POST', headers: {
                'Content-Type': 'application/json'}, body: JSON.stringify({fbUserId: userId, fbToken: token})})
                .then(res => res.json())
                .then(
                  (signInResult) => {
                    if (signInResult.status == "fail") {
                      // register
                      fetch("https://graph.facebook.com/v7.0/" + userId + "/?fields=email,name&access_token=" + token, {
                        method: 'GET', headers: {
                          'Content-Type': 'application/json'}})
                          .then(res => res.json())
                          .then(
                            (userProfileResult) => {
                              fetch(host + "/signupFacebook", {
                                method: 'POST', headers: {
                                  'Content-Type': 'application/json'}, body: JSON.stringify({name: userProfileResult.name, email: userProfileResult.email, fbUserId: userId, fbToken: token})})
                                  .then(res => res.json())
                                  .then(
                                    (result) => {
                                      if ("success" == result.status) {
                                        Storage.set({key:"userDetails", value: JSON.stringify(result.userDetails)});
                                        document.location.href = "/Setup";
                                      } else if ("exists" == result.status && result.email && result.email.length > 0) {
                                        Storage.set({key:"userAlreadyExists", value: result.email}); 
                                        document.location.href = "/SignIn";
                                      }
                                    },
                                    (error) => {
                                      log.error("error signupFacebook (" + userProfileResult.name + " / " + userProfileResult.email + "): " + error);
                                      alert(error);
                                    }
                                  );
                            },
                            (error) => {
                              log.error("error facebookSignIn (" + userId + "): " + error);
                            }); //-fetch
                    } else if (signInResult.status == "success") {
                      Storage.set({key:"userDetails", value: JSON.stringify(signInResult.userDetails)});
                      document.location.href = "/Events";
                    }
                  
                  },
                  (error) => {
                    log.error("error facebookSignIn2 (" + userId + "): " + error);
                  }
                );
      }

      return (
          <div>
        {isMobile && !isWeb? (
            <IonButton className="login-button" onClick={() => facebookSignIn()} expand="full" fill="solid" color="primary">
              Log In with Facebook
            </IonButton>
      ):(
        <FacebookLogin
        appId="276249436960211"
        autoLoad={isRetryAsWeb}
        fields="name,email,picture" //,public_profile,email,user_photos"
        // onClick={()=>{alert(1);}}
        scope="public_profile,email,user_photos"
        callback={responseFacebook}
        disableMobileRedirect={true}
        />
      )}
      </div>
      )
}

export default FaceBookSignInUpContainer;