import React, { useState } from 'react';
import { IonButton, IonLabel, IonHeader, IonToolbar, IonIcon, IonTitle, IonInput, IonItem, IonFooter, IonPopover, IonSlides, IonSlide, IonFab, IonFabButton, IonButtons, IonList, IonListHeader, IonToggle, IonToast, IonText, IonTextarea } from '@ionic/react';
import './MPContainer.css';
import { Plugins } from '@capacitor/core';
import {host} from '../App.config.json';
import {getFirstName, swipeSlider} from './utils';
import socketIOClient from "socket.io-client";
import ReactDOM from 'react-dom';
import {setUnreadMessages} from './utils';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';
import PaymentsContainer from './PaymentsContainer';

interface ContainerProps { }


let initted = false;
let userDetails : any;
let toUserId : any;
// let client : any;
let messagesCache : any[] = [];
let messagesEnd : any;
const today = new Date();
today.setHours(0,0,0,0);

const slideOpts = {
  initialSlide: 0,
  speed: 400
};

let slider : any;
let cancellingGoing = false;

const ChatContainer: React.FC<ContainerProps> = () => {

  const { Storage } = Plugins;
  const { Keyboard } = Plugins;

  const [matches, setMatches] = useState([]);


  const [messages, setMessages] = useState<any[]>([]);
  const [profile, setProfile] = useState<any>();
  const [matchedEvents, setMatchedEvents] = useState<any[]>([]);
  const [organizerOfEvents, setOrganizerOfEvents] = useState<any[]>([]);
  const [goesToMyEvents, setGoesToMyEvents] = useState<any[]>([]);

  const [wideScreenMode, setWideScreenMode] = useState(false);

  const init = async () => {

    if (!initted) {
      initted = true;
      let _host = host;

      if (window.innerWidth > 800) {
        setWideScreenMode(true);
      }

      const bugFix = () => {
        setTimeout(() => {
          let mycontent = document.getElementsByClassName("mycontent");
          if (mycontent && mycontent[0]) {
            (mycontent[0] as HTMLIonContentElement).style.display = "none";
            setTimeout(() => {
              (mycontent[0] as HTMLIonContentElement).style.display = "block";
              messagesEnd.scrollIntoView({ behavior: "auto" });
            }, 10);
          }
        }, 10);
      }

      try {
        Keyboard.show().then(()=>{
          Keyboard.hide();
          Keyboard.addListener( "keyboardDidShow", bugFix);
          Keyboard.addListener( "keyboardDidHide", bugFix);
      }).catch((e)=>{})
      } catch (e){}
        
      

      // client = new w3cwebsocket("ws" + _host.substr(4) + "/chat");

      let userDetailsStr = await Storage.get({key:"userDetails"});
      userDetails = JSON.parse(userDetailsStr.value + "");
      loglevelServerSend(log,{user: userDetails});
      
      const params = new URLSearchParams(document.location.search);
      
      toUserId = params.get("toUserId");

      // remove this so that we'll get notifications for new messages from this user when we leave the chat
      Storage.remove({key:"newMsgFrom_" + toUserId});

      // unset the unread (top icon dot) if it's the last unread thread
      let unreadStr = await Storage.get({key:"unread"});
      if (unreadStr && unreadStr.value) {
        let unread = JSON.parse(unreadStr.value + "");
        if (unread && unread.value && unread.value.indexOf(toUserId) > -1) {
          unread.value.splice(unread.value.indexOf(toUserId), 1);
          Storage.set({key:"unread", value: JSON.stringify(unread.value)});
          if (unread.value.length == 0) {
            setUnreadMessages(false);
          }
        }
        
      }


      fetch(host + "/getProfile", {
        method: 'POST', headers: {
          'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, toUserId: toUserId})})
          .then(res => res.json())
          .then(
            (result) => {
              if (result.status == "success") {
                // profile = result.profile;
                setProfile(result.profile);
              } else {
                log.error("error getProfile " + toUserId);
                document.location.href = "/Chats";
              }
            },
            (error) => {
              log.info("error: " + error);
              document.location.href = "/Chats";
            }
          );
          // -fetch
      
      getMatchedEvents();

      const isOrganizerOfEvents = function (messages: any[]) {
        var _organizerOfEvents = [], _goesToMyEvents = [];
        for (let i = 0; i < messages.length; i++) {
          if (messages[i].body.indexOf("_WELCOME2EVENT_") > -1) {
            const parts = messages[i].body.split("_");
            if (messages[i].fromUserId == userDetails.id) {
              if (_goesToMyEvents.indexOf({eventId: parts[1], eventName: parts[3]}) == -1) {
                let exists = false;
                for (let j = 0; j < _goesToMyEvents.length; j++) {
                  if (_goesToMyEvents[j].eventId == parts[i]) {
                    exists = true;
                    break;
                  }
                }
                if (exists) continue;
                _goesToMyEvents.push({eventId: parts[1], eventName: parts[3]});
              }
            } else if (messages[i].toUserId == userDetails.id) {
              if (_organizerOfEvents.indexOf({eventId: parts[1], eventName: parts[3]}) == -1) {
                let exists = false;
                for (let j = 0; j < _organizerOfEvents.length; j++) {
                  if (_organizerOfEvents[j].eventId == parts[i]) {
                    exists = true;
                    break;
                  }
                }
                if (exists) continue;
                _organizerOfEvents.push({eventId: parts[1], eventName: parts[3]});
              }
            }
          }
          setOrganizerOfEvents(_organizerOfEvents);
          setGoesToMyEvents(_goesToMyEvents);
        }
      }
      
      fetch(host + "/getMessages", {
        method: 'POST', headers: {
          'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, toUserId: toUserId})})
          .then(res => res.json())
          .then(
            (result) => {
              if (result.status == "success") {
                messagesCache = result.messages;
                setMessages(result.messages);
                isOrganizerOfEvents(result.messages);
                messagesEnd.scrollIntoView({ behavior: "smooth" });
                if (result.messages.length == 0) {
                  setTimeout(() => {
                    setShowProfilePopover(true);
                  }, 1000);
                  
                }
              } else {
                log.error("error getMessages " + toUserId);
                document.location.href = "/Chats";
              }
            },
            (error) => {
              log.error("error getMessages (req): " + toUserId + " error: " + error);
              document.location.href = "/Chats";
            }
          );
          // -fetch
      // socket

      
      // client.onopen = () => {
      //   client.send(JSON.stringify({token: userDetails.login.token, toUserId: toUserId, type: "login"}));
      // }
      // client.onmessage = (msg:any) => {
      //   const dataFromServer = JSON.parse(msg.data.toString());
      //   if (dataFromServer.type == "message") {
      //     addMsg(dataFromServer.body);
      //   } else if (dataFromServer.type == "settings") {
      //     getMatchedEvents();
      //   } else if (dataFromServer.type == "unmatched") {
      //     getMatchedEvents();
      //     addMsg("*** The user has unmatched ***");
      //     setInputDisabled(true);
      //   }
      // }
      // client.onclose = function() {
      //   log.info("ws closed");
      // }
      // client.onerror = function() {
      //   log.error("error in ws");
      // }

      const socket = socketIOClient(_host + "", {
        query: {fromUserId: userDetails.id, toUserId: toUserId, type: "chat"}
      });
      
      socket.on("FromAPI", (data: any) => {
        // log.info(data);
        let dataObj = JSON.parse(data);
        if (dataObj.type == "message") {
          addMsg(dataObj.body, new Date(dataObj.time));
        } else if (dataObj.type == "settings") {
              getMatchedEvents();
        } else if (dataObj.type == "unmatched") {
          getMatchedEvents();
          addMsg("*** The user has unmatched ***");
          setInputDisabled(true);
        }
        
      });

            
    }
  
  }
  init();

  const [inputDisabled, setInputDisabled] = useState(false);

  const addMsg = (msg: any, time?: Date) => {
    messagesCache.push({"fromUserId": toUserId, body: msg, time: time});
    var newmsgs = [...messagesCache];
    setMessages(newmsgs);
    if (!showMenuPopover)
    messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  const getMatchedEvents = () => {
    fetch(host + "/getMatchedEvents", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, profileId: toUserId})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
              // profile = result.profile;
              setMatchedEvents(result.matchedEvents);
            } else {
              log.error("error getMatchedEvents " + toUserId);
              document.location.href = "/Chats";
            }
          },
          (error) => {
            log.info("error: " + error);
            document.location.href = "/Chats";
          }
        );
        // -fetch
  }

  const [chatSendButtonImg, setChatSendButtonImg] = useState("assets/icon/send.svg");

  const inputChanged = (_input: string) => {
    if (_input.length > 0) {
      setChatSendButtonImg("assets/icon/send-on.svg");
    } else {
      setChatSendButtonImg("assets/icon/send.svg");
    }
    setInput(_input);
  }

  const [input, setInput] = useState("");
  

  const _sendMessage = () => {
    sendMessage();
  }
  const sendMessage = (param?: string) => {
    if (!param && input.trim().length == 0) return;
    messagesCache.push({fromUserId: userDetails.id, body: param? param : input, time: new Date().toLocaleString() + ""});
    var newmsgs = [...messagesCache];
    setMessages(newmsgs);
    // client.send(JSON.stringify({token: userDetails.login.token, toUserId: toUserId, body: input, type: "message"}));
    fetch(host + "/sendMessage", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, toUserId: toUserId, body: param? param : input})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
              // log.info("msg sent");
            } else {
              log.error("error sendMessag " + toUserId);
            }
          },
          (error) => {
            log.info("error: " + error);
          }
        ); // -fetch
    setInput("");
    messagesEnd.scrollIntoView({ behavior: "smooth" });

  }

  const enterPress = (event : any) => {
    if (!event.shiftKey && event.key == "Enter") sendMessage();
  }

  let lastTime : Date;

  const printTime = (stamp: string) => {
    let stampDate = stamp? new Date(stamp) : new Date();
    if (!lastTime) {
      lastTime = stampDate;
      return lastTime.toLocaleString();
    }
    let newDate = new Date(lastTime);
    newDate.setMinutes(newDate.getMinutes() + 15);
    if (stampDate > newDate) {
      lastTime = stampDate;
      return stampDate.toLocaleString();
    }
  }

  const getSettingColor = (msg : string) => {
    let parts = msg.substr("###WTGWCODE=".length).split("_");
    if (parts[2] == "UNGREEN" || parts[2] == "GRAY") return "gray";
    else if (parts[2] == "GREEN") return "green";
    else if (parts[2] == "YELLOW") return "yellow";
  }

  const getSettingMsg = (msg : any) => {
    let parts = msg.body.substr("###WTGWCODE=".length).split("_");
    let eventName = "-";
    for (let i = 0; i < matchedEvents.length; i++) {
      if (matchedEvents[i].id == parts[1]) {
        eventName = matchedEvents[i].eventName;
        break;
      }
      
    }
    if (userDetails.id == parts[0]) {
      // my action
      if (parts[2] == "GREEN") {
        return "You will go with " + profile.userName + " to " + eventName;
      } else if (parts[2] == "UNGREEN") {
        return "You won't go with " + profile.userName + " to " + eventName;
      } else if (parts[2] == "YELLOW") {
        return "You want to go with " + profile.userName + " to " + eventName;
      } else if (parts[2] == "GRAY") {
        return "You won't go with " + profile.userName + " to " + eventName;
      } else if (parts[2] == "TICKET") {
        return <span>You bought {parts[3]} ticket{parts[3] == "2"? "s":""} to {eventName} <br/>Contact the organizer <a href="#">(Tim Schultz)</a> in the chat about any concern</span>;
      }
    } else {
      // other user's action
      if (parts[2] == "GREEN") {
        return profile.userName + " will go with go you to " + eventName;
      } else if (parts[2] == "UNGREEN") {
        return profile.userName + "  will not go with you to " + eventName;
      } else if (parts[2] == "YELLOW") {
        return profile.userName + " wants to go with you to " + eventName;
      } else if (parts[2] == "GRAY") {
        return profile.userName + " is not interested to go with you to " + eventName;
      } else if (parts[2] == "TICKET") {
        return profile.userName + " has bought " + parts[3] + " ticket" + (parts[3] == "2"? "s":"") + " to " + eventName;
      } else if (parts[2] == "WELCOME2EVENT") {
        if (msg.fromUserId == userDetails.id) {
          return "" + profile.userName + " is going to " + parts[3];
        } else {
          return "You can now write to " + profile.userName + ", the organizer of " + parts[3];
        }
        
      }
    }
  }

  const [showProfilePopover, setShowProfilePopover] = useState(false);
  const [showMenuPopover, setShowMenuPopover] = useState<{open: boolean, event: Event | undefined}>({
    open: false,
    event: undefined,
  });

  const setGoing = (eventId : string, going : any) => {
    if (cancellingGoing) {
      cancellingGoing = false;
      return;
    }

    cancellingGoing = true;
    // setTimeout(() => {
    //   cancellingGoing = false;
    //   log.info("cancellingGoing = false (" + cancellingGoing + ")");
    // }, 5000);
    
    fetch(host + "/setGoing", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: eventId, profileId: toUserId, going: going.checked})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
              cancellingGoing = true;
              if (result.going == "together") {
                going.color = "success";
              }
              let _matchedEvents = [...matchedEvents];
              for (let i = 0; i < _matchedEvents.length; i++) {
                _matchedEvents[i].going = result.going == "together"? "together" : result.going == "going"? "going" : "-";
              }
              setMatchedEvents(_matchedEvents);
              // // client.send(JSON.stringify({token: userDetails.login.token, toUserId: toUserId, type: "settings"}));
              
              // getMatchedEvents();
            } else {
              log.error("error setGoing " + toUserId + " > " + eventId);
              cancellingGoing = true;
              showToast(result.reason);
              going.checked = !going.checked;
            }
          },
          (error) => {
            log.info("error: " + error);
            cancellingGoing = true;
            going.checked = !going.checked;
          }
        ); // -fetch
  }


  function showToast (msg: string) {
    let notificationsDIV = document.getElementById('notifications');
    var newDiv = document.createElement("div");
    if (notificationsDIV) {
        notificationsDIV.appendChild(newDiv);
    }
    ReactDOM.render(<IonToast
      isOpen={true}
      onDidDismiss={()=>{let notificationsDiv = document.getElementById('notifications'); if (notificationsDiv) notificationsDiv.innerHTML = "";}}
      message={msg}
      duration={2000}
  />, newDiv);
  }

  const unmatch = () => {
    var sure = window.confirm("Are you sure?");
    if (sure) {
      fetch(host + "/unmatch", {
        method: 'POST', headers: {
          'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, profileId: toUserId})})
          .then(res => res.json())
          .then(
            (result) => {
              if (result.status == "success") {
                // client.send(JSON.stringify({token: userDetails.login.token, toUserId: toUserId, type: "unmatched"}));
                document.location.href = "/chats";
              } else {
                log.error("error unmatch " + toUserId);
              }
            },
            (error) => {
              log.info("error: " + error);
            }
          ); // -fetch
    }
    
    setShowMenuPopover({open: false, event: undefined})
  }

  const buyTicket = (eventName: string, eventId: string, price: number) => {
    setBuyForEventId(eventId);
    setBuyForEventName(eventName);
    setPayType("ticket");
    setPrice(price);
    setShowPaymntPopover(true);
  }

  const buyCoupon = (eventName: string, eventId: string, price: number) => {
    setBuyForEventId(eventId);
    setBuyForEventName(eventName);
    setPayType("coupon");
    setPrice(price);
    setShowPaymntPopover(true);
  }

  const buyComplete = (howMany: number) => {
    var wtgwcode = "###WTGWCODE=" + userDetails.id + "_" + buyForEventId + "_TICKET_" + howMany;
    sendMessage(wtgwcode);
    setShowPaymntPopover(false);
    setShowMenuPopover({open: false, event: undefined});
  }

  const [showPaymntPopover, setShowPaymntPopover] = useState(false);
  const [buyForEventId, setBuyForEventId] = useState("");
  const [buyForEventName, setBuyForEventName] = useState("");
  const [payType, setPayType] = useState("");
  const [price, setPrice] = useState(0);
  
  return (
    <div className={"mpcontainer maincontainer chatContainer" + (isIOS? " iosPaddingTop" : "")}>
      <IonPopover
        isOpen={showPaymntPopover}
        cssClass={wideScreenMode? 'genericPopover genericPopoverWide' : 'genericPopover'}
        onDidDismiss={() => setShowPaymntPopover(false)} 
        >
          <div>
            <PaymentsContainer paytype={payType} price={price} eventId={buyForEventId} done={buyComplete}></PaymentsContainer>
          </div>
        </IonPopover>
      {profile?(
      <IonPopover
        isOpen={showProfilePopover}
        cssClass={wideScreenMode? 'chatProfilePopover chatProfilePopoverWide':'chatProfilePopover'}
        onDidDismiss={() => setShowProfilePopover(false)}
        >
          {profile.pictures.length > 0? (
            <div>
          <IonSlides pager={true} options={slideOpts} ref={e => slider= e} onClick={(e:any)=>{swipeSlider(e, slider);}}>
            {profile.pictures.map((picture:any) =>
            <IonSlide>
              <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + profile.id + "_" + picture + (picture == "teamlogo"? ".png":".jpeg")} className="profilePhoto"/>
            </IonSlide>
            )}
            {profile.pictures.length == 0? (
            <IonSlide>
            <img src={"assets/icon/defaultuserphoto.svg"} className="profilePhoto"/>
          </IonSlide>
            ):("")}
          </IonSlides>

          <IonFab vertical="bottom" horizontal="end" slot="fixed" className="editiconfab">
            <IonFabButton onClick={()=>{setShowProfilePopover(false)}}>
              <IonIcon  className="editicon" icon="assets/icon/down.svg" />
            </IonFabButton>
          </IonFab></div>
          ):("")}
          {profile.pictures.length == 0? (
            <img src={"assets/icon/defaultuserphoto.svg"} className="profilePhoto"/>
            ):("")}
          <br/><br/>
          <div className="profileDetails">
          <IonLabel className="profileName"><b>{profile.userName}</b> {profile.id == "vVM3dbtNn55eZJShso2AWDxYyx9uVwfN2drwARTiEyal"? (
                      <IonIcon className="" src="assets/icon/verified.svg" />
                    ):""}{profile.id != "vVM3dbtNn55eZJShso2AWDxYyx9uVwfN2drwARTiEyal"? ", " + profile.age : ""}</IonLabel><br/>
          <IonLabel className="profileCity">{profile.id != "vVM3dbtNn55eZJShso2AWDxYyx9uVwfN2drwARTiEyal"? profile.city : ""}</IonLabel>
            
            <br/><br/>
            <div className={"profileBio" + (/[א-ת].+/.test(profile.about)? " rtl":"")}>
            <span className="preLine">{profile.about}</span>
            </div><br/><br/></div>
        </IonPopover>):("")}
      <IonHeader className={wideScreenMode? "myheaderChat myheaderWide":"myheaderChat"}>
        <IonToolbar>
        <IonButton type="button" 
            slot="start" href="/Chats">
              <IonIcon className="" src="assets/icon/left-arrow.svg" />
            </IonButton>
            <IonTitle slot="">
              {profile? (<div className="topChat cursorPointer" onClick={()=>{setShowProfilePopover(true)}}>
            <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + profile.id + "_" + profile.pictures[0] + (profile.pictures[0] == "teamlogo"? ".png":".jpeg")} className="chatProfileTopPhoto"/><br/>
              <div className="capitalize topChatName">{profile.userName} {profile.id == "vVM3dbtNn55eZJShso2AWDxYyx9uVwfN2drwARTiEyal"? (
                      <IonIcon className="" src="assets/icon/verified.svg" />
                    ):""}</div></div>):("")}</IonTitle>
              <IonButtons slot="end">
                {profile && profile.id != "vVM3dbtNn55eZJShso2AWDxYyx9uVwfN2drwARTiEyal"? (
                <IonButton onClick={(e)=>{setShowMenuPopover({open: true, event: e.nativeEvent})}}>
                  <IonIcon src="assets/icon/ellipsis-vertical-outline.svg" name="ellipsis-vertical-outline"></IonIcon>
                </IonButton>):""}
              </IonButtons>
          </IonToolbar>
      </IonHeader>
      <IonPopover
        isOpen={showMenuPopover.open}
        event={showMenuPopover.event}
        cssClass='my-custom-class'
        onDidDismiss={e => setShowMenuPopover({open: false, event: undefined})}>
        <IonList>
          
            {profile && matchedEvents.length != 0? (
              <IonListHeader className="chatMenuHeader">
                <div>Going with <span className="capitalize">{getFirstName(profile.userName)}</span> to:</div>
              </IonListHeader>
            ):("")}
          
            {matchedEvents.map(event => 
            <span>
            <IonItem>
            {event.eventName}
            <IonToggle disabled={new Date(event.start) < today || event.deleted} color={event.going == "together"? "success":"warning"} slot="end" checked={event.going != "-"} onIonChange={(e)=>{setGoing(event.id as string, e.target)}} />
            </IonItem>
            <IonItem disabled={event.going != "together" || new Date(event.start) < today || event.deleted} className="cursorPointer" onClick={()=>{buyTicket(event.name, event.id, event.ticketPrice * 0.85)}}>
            <img className="menuIcon" src="assets/icon/ticket.jpg"/> &nbsp;&nbsp; <span className="red">-15%</span>  &nbsp;  Buy tickets
            </IonItem>
            <IonItem hidden={true} disabled={event.going != "together" || new Date(event.start) < today || event.deleted} className="cursorPointer" onClick={()=>{
              //alert("To get the 10% discount coupon you'll be asked to pay 5% now.\n\nYou can cancel the coupon any time up to 24 hours before the event and get a full refund.\n\nAt the event you'll get the ticket for 15% off the price."); 
              buyCoupon(event.name, event.id, event.ticketPrice * 0.05);
              }}>
            <img className="menuIcon" src="assets/icon/qrcodeiconsmall.png"/> &nbsp;&nbsp; <span className="red">-10%</span>  &nbsp;  Get Coupon 
            </IonItem>
            </span>
            )}

          <IonItem className="cursorPointer" onClick={unmatch} hidden={matchedEvents.length == 0}>
              <IonIcon src="assets/icon/nope.svg"></IonIcon> &nbsp; Unmatch
          </IonItem>
          
            {profile? (
              organizerOfEvents.length == 0? (
                ""
              ):(
                <IonListHeader className="chatMenuHeader">
                  <div><span className="capitalize">{getFirstName(profile.userName)}</span> is the host of:</div>
                </IonListHeader>
              )
            ):("...")}
            {organizerOfEvents.map(event =>
              <span>
                <IonItem>
                  {event.eventName}
                </IonItem>
              </span>
              )}
          
          {profile? (
              goesToMyEvents.length == 0? (
                ""
              ):(
                <IonListHeader className="chatMenuHeader">
                  <div><span className="capitalize">{getFirstName(profile.userName)}</span> is going to:</div>
                </IonListHeader>
              )
            ):("...")}
            {goesToMyEvents.map(event =>
              <span>
                <IonItem>
                  {event.eventName}
                </IonItem>
              </span>
              )}
        </IonList>
      </IonPopover>
      <br/>
      <div className="mycontentChat">
        
        {
        messages.map(msg => 
          <div><div className="chatTimeStamp">{printTime(msg.time)}</div>
          {msg.body.startsWith("###WTGWCODE=")? (   
            <div className="chatTimeStamp"><br/>
            {profile? (<div>
            {getSettingMsg(msg)} 
            {msg.body.indexOf("_SETGOING") != -1?(
            <img className="goingIconInChat" src={'assets/icon/going-' + getSettingColor(msg.body) + '.png'}/>
            ): msg.body.indexOf("_TICKET_") != -1? (
              <div><br/>
              <img style={{height: 50}} src="assets/icon/ticket.png"/> 
              <img style={{height: 50, display: msg.body.indexOf("_TICKET_2") != -1? "inline":"none"}} src="assets/icon/ticket.png"/>
              </div>
            ):""}
            <br/>
            </div>):("")}</div>
          ):(
            <div className={msg.fromUserId == toUserId? "fromMsg" : "toMsg"}>
              <div className="inlineDiv">
              <div className="chatBGFrom">
              {msg.body}
              </div></div>
            <br/>
            </div>)}
          </div>
      )}
      <br/><br/><br/>
      <div style={{ float:"left", clear: "both" }}
             ref={(el) => { messagesEnd = el; }}>
        </div>
      </div>
      <IonFooter className={wideScreenMode? "myfooter myfooterWide" : "myfooter"}>
      <div className={"chatFooter" + (isIOS? " chatFooterIos":"")}>
        <IonItem>
      {/* <IonInput disabled={inputDisabled} name="chatInput" placeholder="Type a message..." maxlength={300} onIonChange={(e)=>{inputChanged((e.target as HTMLInputElement).value); return false;}} value={input} onKeyPress={enterPress} ></IonInput>  */}
      {/* <IonTextarea className="chatTextarea" rows={1} autoGrow={true} id="chatInput" placeholder="Type a message..." 
      value={input}
      onIonChange={(e : any)=>{var that = (e.target as HTMLIonTextareaElement); inputChanged(that.value + ""); return false;}} onKeyPress={(e)=>{enterPress(e); return false;}}></IonTextarea> */}
      {/* <textarea name="chatInput" className="chatTextarea" rows={1} onKeyPress={(e)=>{enterPress(e); return false;}} onChange={(e)=>{inputChanged(e.target.value)}} value={input}>{input}</textarea> */}
      <input type="text" name="chatInput" className="chatTextarea"  onKeyPress={(e)=>{enterPress(e); return false;}} onChange={(e)=>{inputChanged(e.target.value)}} value={input}></input>
      <img src={chatSendButtonImg} className="chatSendButton cursorPointer" onClick={_sendMessage}/>
      </IonItem>
      </div>
      </IonFooter>
    </div>
  );
};

export default ChatContainer;
