import React, { useState }  from 'react';
import { IonButton, IonTextarea, IonHeader, IonToolbar, IonIcon, IonDatetime, IonTitle, IonContent, IonBadge, IonPopover, IonCheckbox, IonItem } from '@ionic/react';
import { IonInput, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useForm } from "react-hook-form";
import { Plugins } from '@capacitor/core';
import {host} from '../App.config.json';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
// import Cropper from 'react-cropper';
// import 'cropperjs/dist/cropper.css'
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';
// import 'react-image-crop/dist/ReactCrop.css';
import '../theme/ReactCrop.css';


import './MPContainer.css';
import PicturesEditContainer from './PicturesEditContainer';

interface ContainerProps { }

// const cropper = React.createRef();
// var Cropper = require('react-cropper').default;

let initted = false;
let userDetails : any;
let eventDetails : any;
let _eventId : any;

const EditEventContainer: React.FC<ContainerProps> = () => {

  const [] = useState(false);

  const [] = useState("");
  defineCustomElements(window);
  const [eventName, setEventName] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventTypeOther, setEventTypeOther] = useState("");
  const [approved, setApproved] = useState("");
  var dt = new Date();
  dt.setMonth(dt.getMonth() + 1);
  const startDateInit = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
  const maxStartDate = (dt.getFullYear() + 1) + "-12-31";
  const [startDate, setStartDate] = useState(startDateInit);
  const [eventAbout, setEventAbout] = useState("");
  // const [pictures, setPictures] = useState([]);
  const [ticketURL, setTicketURL] = useState("");
  const [ticketPrice, setTicketPrice] = useState(0);
  const [eventIsFree, setEventIsFree] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [offerTickets, setOfferTickets] = useState(false);
  

  function handle_setStartDate (val:any) {
    setStartDate(val);
    Storage.set({key: "eventStartDate", value: val});
  }

  function handle_setEventType (val:any) {
    setEventType(val);
    // Storage.set({key: "eventType", value: val});
    if (val != "Other") {
      setEventTypeOther(val);
    } else {
      setEventTypeOther("");
    }
  }

  const [] = useState(false);
  const [] = useState(true);
  const [eventId, setEventId] = useState(String);
  const { Storage } = Plugins;

  const [wideScreenMode, setWideScreenMode] = useState(false);
  const [newEventPopover, setNewEventPopover] = useState(false);

  const init = async () => {
    if (!initted) {
      initted = true;

      if (window.innerWidth > 800) {
        setWideScreenMode(true);
      }

      let userDetailsStr = await Storage.get({key:"userDetails"});
      userDetails = JSON.parse(userDetailsStr.value + "");

      loglevelServerSend(log,{user: userDetails});

      const params = new URLSearchParams(document.location.search);
      
      _eventId = params.get("id");

      if (_eventId == "new") {
        
        setNewEventPopover(true);

        fetch(host + "/createEvent", {
          method: 'POST', headers: {
            'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: _eventId})})
            .then(res => res.json())
            .then(
              (result) => {
                var event = result.eventDetails;
                if (event) {
                  eventDetails = event;
                  setEventId(event.id);
                  userDetails = result.userDetails;
                  const userDetailsStringified = JSON.stringify(result.userDetails);
                  Storage.set({key:"userDetails", value: userDetailsStringified});
                }
              },
              (error) => {
                log.error("error createEvent (" + _eventId + "): " + error);
              }
            ); // -fetch
      } else {
        setEventId(_eventId + "");

        fetch(host + "/getEvent", {
          method: 'POST', headers: {
            'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: _eventId})})
            .then(res => res.json())
            .then(
              (result) => {
                var event = result.eventDetails;
                if (event) {
                  eventDetails = event;
                  setEventName(event.eventName);
                  setEventLocation(event.location);
                  setStartDate(event.start);
                  setEventAbout(event.about);
                  setEventType(event.eventType);
                  setEventTypeOther(event.eventTypeStr);
                  setApproved(event.approved);
                  setTicketURL(event.ticketURL);
                  setTicketPrice(event.ticketPrice);
                  setEventIsFree(event.eventIsFree);
                  setRecurring(event.recurring);
                  setOfferTickets(event.offerTickets);
                }
              },
              (error) => {
                log.error("error getEvent (" + _eventId + "): " + error);
              }
            ); // -fetch
          }
    }
  }
  init();

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data : any) => {
    
    eventDetails.eventName = eventName;
    eventDetails.location = eventLocation;
    eventDetails.about = eventAbout;
    // eventDetails.start = (await Storage.get({key:'eventStartDate'})).value; // used this because there was a problem in the past with this field
    var nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 2);
    nextDay.setHours(0, 0, 0, 0);
    if (new Date(startDate) < nextDay) {
      alert("Event start can't be less than 2 days from today");
      return;
    }
    eventDetails.start = startDate;
    if (eventType == "") {
      alert("Please choose an event type");
      return;
    } else if (eventType == "Other" && eventTypeOther.length < 3) {
      alert("Please specify the event type");
      return;
    }
    eventDetails.eventType = eventType;
    eventDetails.eventTypeStr = eventTypeOther;
    eventDetails.ticketURL = ticketURL;
    eventDetails.ticketPrice = ticketPrice;
    eventDetails.eventIsFree = eventIsFree;
    eventDetails.recurring = recurring;
    if (!eventIsFree && (!ticketPrice || ticketPrice < 1)) {
      alert("If the event is not free, ticket price must be filled");
      return;
    }
    eventDetails.offerTickets = offerTickets;
    
    fetch(host + "/setupEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({event: eventDetails, token: userDetails.login.token})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
              showStatus("Update successful.");
              document.location.href = "/Events";
            } else {
            showStatus("Update failed.");
            }
          },
          (error) => {
            log.error("error setupEvent (" + eventDetails.eventName + "): " + error);
            showStatus("Update failed.");
          }
        );
        // -fetch
    
  

    

  }; // onSubmit
  
  function showStatus (txt : string) {
    var statusLabel = document.getElementById("statusLabel");
    if (statusLabel) {
      statusLabel.classList.remove("displayNone");
      statusLabel.innerText = txt;
    }
  }

  const savePhotos = (photos: []) => {
    eventDetails.pictures = photos;
    
    fetch(host + "/setupEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({event: eventDetails, token: userDetails.login.token})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
            //   showStatus("Update successful.");
              // document.location.href = "/Profile";
            } else {
            // showStatus("Update failed.");
            }
          },
          (error) => {
            log.error("error setupEvent (" + eventDetails.eventName + "): " + error);
            // showStatus("Update failed.");
          }
        );
        // -fetch
  }

  const cancelDeleteEvent = () => {
    let sure = window.confirm("Are you sure?");
    if (!sure) return;
    fetch(host + "/deleteEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({eventId: eventId, token: userDetails.login.token})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
            //   showStatus("Update successful.");
              document.location.href = "/Events";
            } else {
            // showStatus("Update failed.");
            }
          },
          (error) => {
            log.error("error deleteEvent (" + eventId + "): " + error);
            // showStatus("Update failed.");
          }
        );
        // -fetch
  
  }
  
  return (
    <div className="mpcontainer">
    <form onSubmit={handleSubmit(onSubmit)} id="mainform">
     <IonHeader>
        <IonToolbar>
            {/* <IonButton href="/Profile" disabled>
              <IonIcon className="topicon" src="assets/icon/profile-on.svg" />
            </IonButton>
            <IonButton disabled>
            <IonIcon className="topicon" src="assets/icon/events.svg" />
            </IonButton>
            <IonButton href="/Home" disabled>
              <IonIcon slot="icon-only" src="assets/icon/swipe.svg" />
            </IonButton>
            <IonButton disabled>
              <IonIcon slot="icon-only" src="assets/icon/chat.svg" />
            </IonButton> */}
            <IonButton type="submit" 
            // onClick={()=>{let mainform = document.getElementById("mainform") as HTMLFormElement;if (mainform)mainform.submit();}} 
            slot="start" href="/Events">
              <IonIcon className="" src="assets/icon/left-arrow.svg" />
            </IonButton>
            <IonTitle slot="start">Edit Event</IonTitle>
        </IonToolbar>
    </IonHeader>

{eventId != null && eventId.length > 1 && eventId != "new"? (
    <PicturesEditContainer save={savePhotos} eventId={eventId} aspect={10/7} maxPhotos={1} />
  ):""}
      <div className="setupLabel">
      <IonLabel color="medium">Event Name: </IonLabel>
      <IonInput name="eventName" placeholder="" maxlength={50} ref={register({ required: true })} onBlur={(e)=>{setEventName(e.target.value + ""); return false;}} value={eventName}></IonInput>
      <IonLabel color="warning">{errors.eventName && (errors.eventName.message || "Event Name is required")}</IonLabel>
      <br/>
      <IonLabel color="medium">Event Location</IonLabel>
      <IonInput name="eventLocation" placeholder="Location" maxlength={60} ref={register()} onBlur={(e)=>{setEventLocation(e.target.value + ""); return false;}} value={eventLocation}></IonInput>
      <br/>
      <IonLabel color="medium">Event Start Date</IonLabel>
      <IonDatetime id="startDate" max={maxStartDate} name="startDate" value={startDate} onIonChange={(e)=>{handle_setStartDate((e.target as HTMLInputElement).value)}}></IonDatetime>
      <br/>
      <IonLabel color="medium">Type</IonLabel>
      <IonSelect name="eventType" value={eventType} placeholder="select..." interface="popover" onIonChange={e => {handle_setEventType((e.target as HTMLIonSelectElement).value + "")}}>
        <IonSelectOption value="Class">Class</IonSelectOption>
        <IonSelectOption value="Festival">Festival</IonSelectOption>
        <IonSelectOption value="House Party">House Party</IonSelectOption>
        <IonSelectOption value="Meetup">Meetup</IonSelectOption>
        <IonSelectOption value="Party">Party</IonSelectOption>
        <IonSelectOption value="Show">Show</IonSelectOption>
        <IonSelectOption value="Trip">Trip</IonSelectOption>
        <IonSelectOption value="Wedding">Wedding</IonSelectOption>
        <IonSelectOption value="Workshop">Workshop</IonSelectOption>
        <IonSelectOption value="Other">Other</IonSelectOption>
      </IonSelect>
      <IonLabel color="warning">{errors.eventType && "Event Type is required"}</IonLabel>
      <IonInput hidden={["Other"].indexOf(eventType) == -1} name="eventTypeOther" placeholder="Event Type" maxlength={60} ref={register()} onBlur={(e)=>{setEventTypeOther(e.target.value + ""); return false;}} value={eventTypeOther}></IonInput>
      <br/>
      <IonLabel color="medium">About</IonLabel>
      <IonTextarea rows={5} name="about" value={eventAbout} placeholder="" maxlength={500} ref={register()} onBlur={(e)=>{setEventAbout(e.target.value + ""); return false;}}></IonTextarea>
      <br/>

      <IonItem>
      <IonCheckbox name="recurring" checked={recurring} onIonChange={e => setRecurring(e.detail.checked)}></IonCheckbox>
      <IonLabel>Recurring event</IonLabel>
      </IonItem>

      <div className={"paddingSides smallerFont" + (eventIsFree? " disabledText":"")}>
        When a recurring event start date passes, the payment subscription doesn't cancel. 
        The organizer still needs to manually set the start date to the next.
      </div>

      <IonItem>
      <IonCheckbox name="eventIsFree" checked={eventIsFree} onIonChange={e => setEventIsFree(e.detail.checked)}></IonCheckbox>
      <IonLabel>The event is free of charge</IonLabel>
      </IonItem>


      <IonItem>
      <IonLabel color="medium">Ticket price (full): &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonLabel>
      <IonInput disabled={eventIsFree} name="ticketPrice" type="number" onBlur={(e)=>{setTicketPrice(Number.parseInt(e.target.value + "")); return false;}} value={ticketPrice}></IonInput>
      <IonLabel color="medium">$ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonLabel>
      </IonItem>


      <br/><br/>
      <div hidden={true} className={"paddingSides smallerFont" + (eventIsFree? " disabledText":"")}>
      Coupons for 10% discount will be sold for price of 5% of the ticket.
      <br/><br/>
      The coupons should be respected at the event and traded for tickets at 15% price (10% discount, the 5% paid by the guest is WhoToGoWith's fee). 
      <br/><br/>
      Coupons can be cancelled and and refunded anytime up to 24 hours before the event start.
      <br/></div>

      <IonItem hidden={true}>
      <IonCheckbox name="offerCoupons" checked={true} disabled={true}></IonCheckbox>
      <IonLabel>Offer 10% discount coupons</IonLabel>
      </IonItem>
      {/* <br/><br/> */}
      <div className={"paddingSides smallerFont" + (eventIsFree? " disabledText":"")}>
      Tickets will be sold at 15% discount to couples who match and decide to go together. 
      The money paid will then be transfered to the organizer, minus 10% fee (from the ticket full price).
      </div><br/>



      <IonItem>
      <IonCheckbox disabled={true} name="eventIsFree" checked={true} onIonChange={e => setOfferTickets(e.detail.checked)}></IonCheckbox>
      <IonLabel>Offer Tickets at 15% discount</IonLabel>
      </IonItem>

      <br/><br/>
      <div className={"paddingSides smallerFont" + (eventIsFree || !offerTickets? " disabledText":"")}>
      <b>Tickets purchase confirmation URL (optional)</b> - You may enter a URL to forward users after they purchase a ticket.
      </div>

      <IonItem>
      <IonInput disabled={eventIsFree || !offerTickets} name="ticketURL" placeholder="http://" type="url" maxlength={1250} onBlur={(e)=>{setTicketURL(e.target.value + ""); return false;}} value={ticketURL}></IonInput>
      </IonItem>
        <br/>

      </div>

      <IonPopover
        isOpen={newEventPopover}
        cssClass={wideScreenMode? 'genericPopover genericPopoverWide' : 'genericPopover'}
        onDidDismiss={() => setNewEventPopover(false)} 
        >
          <div className={"genericPopoverInner" + (isIOS? "Ios":"")}>
          <div className="centered">
            {/* One Time | Recurring
            <br/><br/> */}
            
            {/* <b>Event</b>
            <br/><br/> */}
            Create an event on WhoToGoWith
            <br/><br/>
            <b>$5 / Month</b>
            </div>
            <br/><br/>
            Connect singles who want to attend your event
            <br/><br/>
            Get insights about matches and interactions
            <br/><br/>
            Unlimited number of users
            <br/><br/>
            For any question - WTGW support team is on the chat screen,
            <br/>
             or thru email: info@whotogowith.com
            <br/><br/>
            <br/><br/>
            <div className="centered">
            <IonButton size="large" id="startButton" onClick={()=>{setNewEventPopover(false);}} type="button" className="pinkButton">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Start &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
            </div>

          </div>

        </IonPopover>

     
      {_eventId == "new"? "" :(
      <span><br/><br/>
      <IonBadge slot="end" color="success" hidden={approved != "yes"}>
        Event approved
      </IonBadge>
      <IonBadge slot="end" color="danger" hidden={approved != "no"}>
        Event not approved
      </IonBadge>
      <IonBadge slot="end" color="warning" hidden={approved != "pendingpay"}>
        Pending payment (pay from events screen)
      </IonBadge>
      <IonBadge slot="end" color="warning" hidden={approved != "pending"}>
        Pending approval
      </IonBadge>
      </span>
      )}

      <br/><br/>
      <IonButton size="large" id="saveButton" href="Events" type="submit" className="pinkButton">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Save &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
      {_eventId != "new"? (
      <IonButton size="large" id="cancelButton" href="Events"  className="" color="medium" type="button" >Cancel</IonButton>
      ):""}
      <br/><br/><br/><br/>
      <IonLabel id="statusLabel" color="primary" className="displayNone">---</IonLabel><br/>
      <IonButton size="large" id="cancelDeleteButton" fill={_eventId == "new"? "outline" : "clear"} onClick={()=>{cancelDeleteEvent();}} color="medium">{_eventId == "new"? "Cancel" : "Delete Event"}</IonButton>
      
      {/* <IonButton size="large" id="saveButton" className="pinkButton" href="Home" type="submit" >Save</IonButton> */}
      <br></br><br></br>
        {/* {(hasSubscription)? ( */}
      {/* <IonButton id="cancelSubButton" color="warning" fill="clear" href="/subscription">Subscription</IonButton> */}
      {/* ):""} */}
      <br/><br/>
      </form>
    </div>
    
  );
};


export default EditEventContainer;
