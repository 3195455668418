import React, { useState } from 'react';
import { IonButton, IonIcon, IonLabel, IonThumbnail } from '@ionic/react';
import './MPContainer.css';
import { Plugins } from '@capacitor/core';
import {host} from '../App.config.json';
import {getFirstName} from './utils';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';

interface ContainerProps { }


let initted = false;
let userDetails : any;
let conversationsRef : any;
let matchesRef : any;

const ChatsContainer: React.FC<ContainerProps> = () => {

  const { Storage } = Plugins;

  const [matches, setMatches] = useState<any>([]);
  const [conversations, setConversations] = useState<any>([]);
  const [noMatches, setNoMatches] = useState(false);

  const newMessage = (msg : any) => {
    // Search for the profile id in matches and conversations. 
    // If in matches, remove and move to conversations (1st in array). 
    // If in messages, move to 1st and update snippet.
    let found = false;
    if (matchesRef) {
      for (let i = 0; i < matchesRef.length; i++) {
        if (matchesRef[i].id == msg.fromUserId) {
          let match = matchesRef[i];
          match.lastMsgUnread = true;
          match.lastMsg = msg.body;
          let newmatches = [...matchesRef];
          newmatches.splice(i, 1);
          setMatches(newmatches);
          setNoMatches(false);
          var newcons = conversationsRef? [...conversationsRef] : [];
          newcons.unshift(match);
          setConversations(newcons);
          found = true;
          break;
        }
      }
    }
    if (!found && conversationsRef) {
      for (let i = 0; i < conversationsRef.length; i++) {
        if (conversationsRef[i].id == msg.fromUserId) {
          let con = conversationsRef[i];
          con.lastMsgUnread = true;
          con.lastMsg = msg.body;
          var newcons = [...conversationsRef];
          newcons.splice(i, 1);
          newcons.unshift(con);
          setConversations(newcons);
          found = true;
          break;
        }
      }
    }

  }

  const newMatch = () => {
    initted = false;
    init();
  }

  const init = async () => {

    if (!initted) {
      initted = true;
      
      let userDetailsStr = await Storage.get({key:"userDetails"});
      userDetails = JSON.parse(userDetailsStr.value + "");
      
      loglevelServerSend(log,{user: userDetails});

      // update lastMsgSnippet when new msg arrives
      (window as any).newMsgsListeners.push(newMessage);


      (window as any).newMatchListeners.push(newMatch);

      fetch(host + "/getMatches", {
        method: 'POST', headers: {
          'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token})})
          .then(res => res.json())
          .then(
            (result) => {
              if (result.matches && result.matches.length > 0) {
                setMatches(result.matches);
                matchesRef = result.matches;
                setNoMatches(false);
              } else {
                setNoMatches(true);
              }
              if (result.conversations && result.conversations.length > 0) {
                setConversations(result.conversations);
                conversationsRef = result.conversations;
              }
            },
            (error) => {
              log.error("error chats getMatches: " + error);
            }
          ); // -fetch
      
    }
  
  }
  init();

  const showChat = (userId: string) => {
    document.location.href = "/chat?toUserId=" + userId;
  }

  const trimLastMsg = (txt: string) => {
    if (txt.startsWith("###WTGWCODE=")) {
      return ".....";
    }
    return txt.length < 25? txt : txt.substr(0, 25) + "...";
  }

  return (
    <div className={"mpcontainer maincontainer leftalign-nofloat chatscontainer" + (isIOS? " iosPaddingTop" : "")}>
      <br/>
      {matches.length > 0? (
        <div>
      <IonLabel className="pinkLabel boldLabel">New Matches</IonLabel>
      <div className="matchesList">
        {matches.map((match:any) => {
        if (match.userName.indexOf("replaceThisWithMessaged") > -1) return ("");
        return (
          <div className="chatsNewMatch">
            <IonThumbnail className="chatsNewMatchPhoto">
              <a onClick={()=>{showChat(match.id);}} className="cursorHand">
              <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + match.id + "_" + match.pictures[0] + ".jpeg"} className="chatsNewMatchPhoto"/><br/>
              <div className="chatsNewMatchTitle capitalize">{getFirstName(match.userName)}</div>
              </a>
            </IonThumbnail>
          </div>
        )})}
      </div>
      <br/>
      </div>):("")}
      {conversations.length > 0? (
        // <div>
          <div className="messagesList">
          <IonLabel className="pinkLabel boldLabel">Messages</IonLabel>
          <br/><br/>
          {conversations.map((conversation:any) => {
            if (conversation.userName.indexOf("replaceThisWithMessaged") > -1) return ("");
            return (
              <div className="chatsConversation">
                <IonThumbnail className="chatsConversationPhoto">
                  <a onClick={()=>{showChat(conversation.id);}} className="cursorHand">
                  <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + conversation.id + "_" + conversation.pictures[0] + (conversation.pictures[0] == "teamlogo"? ".png":".jpeg")} className={"chatsNewMatchPhoto" + (conversation.id == "vVM3dbtNn55eZJShso2AWDxYyx9uVwfN2drwARTiEyal"? " chatsWTGWTeamIcon":"" )}/>
                  <div className="chatsConversationTitle">
                    <b className="capitalize">{/*getFirstName*/(conversation.userName)} {conversation.id == "vVM3dbtNn55eZJShso2AWDxYyx9uVwfN2drwARTiEyal"? (
                      <IonIcon className="" src="assets/icon/verified.svg" />
                    ):""}</b>
                    {conversation.hostOfEvents? (
                      <span> (host)
                        <br/>
                        {conversation.hostOfEvents.map((userEvent:any, i : number) => {
                          return <span>{userEvent.name} {i + 1 < Object.keys(conversation.hostOfEvents).length? (<span> &nbsp; · &nbsp; </span>) : ""}</span>
                        })}<br/>
                      </span>
                    ):(<br/>)}
                  
                  {conversation.lastMsgUnread? 
                  (
                  <b>
                    {trimLastMsg(conversation.lastMsg)}
                  </b>
                  ):(
                    <div>
                    {trimLastMsg(conversation.lastMsg)}
                    </div>
                    )}
                  </div>
                  </a>
                </IonThumbnail>
              </div>
            )})}
          {/* </div> */}
      </div>):("")}
        {noMatches && matches.length == 0 && conversations.length == 0? (
          <div className="centered">
            <br/><br/><br/>
            <span className="emptyScreenLabel">No matches (yet 😋)</span>
          </div>
        ):("")}
        {matches.length > 0 && conversations.length == 0? (
          <div className="centered">
            <br/><br/><br/>
          <span className="emptyScreenLabel">No conversations</span>
        </div>
        ):("")}
      
    </div>
  );
};

export default ChatsContainer;
