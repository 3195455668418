import React  from 'react';

import './MPContainer.css';

interface ContainerProps { }

const ExitContainer: React.FC<ContainerProps> = () => {

  return (
    <div id="exitDiv"></div>
    
  );
};


export default ExitContainer;
