import React, { useState } from 'react';
import { IonButton, IonInput, IonIcon, IonFab, IonFabButton, IonThumbnail, IonFabList, IonPopover, IonContent, IonItem, IonBadge, IonGrid, IonRow, IonCol } from '@ionic/react';
import './MPContainer.css';
import { Plugins, KeyboardInfo } from '@capacitor/core';
import {host} from '../App.config.json';
import {getEventDateStr} from './utils';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';

interface ContainerProps {
  go?: any,
  eventId?: String,
  eventName?: String,
  eventTypeStr?: String,
  organizerName?: String,
  eventPicture?: String,
  statsGoing?: Number,
  eventLocation?: String,
  eventStart?: String,
  eventAbout?: String,
  eventGoing?: Boolean,
  wideScreenMode: Boolean
 }

let initted = false;

const EventContainer: React.FC<ContainerProps> = ({go = () => {}, eventId = "", eventName = "", eventTypeStr = "", organizerName = "",
eventPicture = "", statsGoing = 0, eventLocation = "", eventStart = "", eventAbout = "", eventGoing = false, wideScreenMode = false}) => {

  const { Storage } = Plugins;
  const { Keyboard } = Plugins;


  // const [wideScreenMode, setWideScreenMode] = useState(false);

  const init = async () => {
    if (!initted) {
      initted = true;
      
      // if (window.innerWidth > 800) {
      //   setWideScreenMode(true);
      // }

      loglevelServerSend(log,{});

      
    } // -initted
  }
  // call init() at the end of this block
  

  const hideKeyboard = () => {
    Keyboard.hide().catch((e)=>{});
  }

  

  init();

  return (
    !wideScreenMode? (
        <div className="foundEvent" onTouchStart={()=>{hideKeyboard();}}>
          <div className="leftalign-nofloat line-height-big organizerAddedEvent">
          <b>{organizerName}</b> has added {(['a','i','o','u','e'].indexOf(eventTypeStr.toLowerCase()[0]) > -1? "an " : "a ") + eventTypeStr}
          </div>
          <div className="eventImgDiv">
          <img src={"https://gowith.s3.us-east-2.amazonaws.com/events/" + eventId + "_" + eventPicture + ".jpeg"} className="profilePhoto"/>
          <div className="eventBadges">
            <IonBadge slot="end" color="primary"><img src="assets/icon/people.svg" className="verticalMiddle" /> {statsGoing}</IonBadge>
            {/* <br/>
            <IonBadge slot="end" color="danger">Matched: {event.statsMatched}</IonBadge>
            <br/>
            <IonBadge slot="end" color="light">Swiped: {event.statsSwiped}</IonBadge> */}

          </div>
          </div>

        <IonButton className={eventGoing? "eventAttendingButton greenButton eventAttendingButtonGoing" : "eventAttendingButton pinkButton"} 
        onClick={()=>{go(eventId); }} disabled={eventGoing? true : false}>{eventGoing? "GOING" : "GO"}</IonButton>
          <div className="leftalign-nofloat line-height-big stretchDiv">
            <span className="pinkLabel smallLabel">{getEventDateStr(eventStart + "")}</span>
            <br/>
            <b>{eventName}</b>
            <br/>
            <span className="smallGreyLabel">{eventLocation}</span>
          </div>
            <div className="line-above leftalign-nofloat eventDesc">
              <div className={"mediumGreyLabel" + (/[א-ת].+/.test(eventAbout + "")? " rtl":"")}><span className="preLine">{eventAbout}</span></div>
            </div>
          <br/>
        </div>
    ): (
      <div className="foundEventWide">
        <IonGrid>
          <IonRow>
            <IonCol>
            <div className="eventImgDiv">
          <img src={"https://gowith.s3.us-east-2.amazonaws.com/events/" + eventId + "_" + eventPicture + ".jpeg"} className="profilePhoto"/>
          <div className="eventBadges">
            <IonBadge slot="end" color="primary"><img src="assets/icon/people.svg" className="verticalMiddle" /> {statsGoing}</IonBadge>
            {/* <br/>
            <IonBadge slot="end" color="danger">Matched: {event.statsMatched}</IonBadge>
            <br/>
            <IonBadge slot="end" color="light">Swiped: {event.statsSwiped}</IonBadge> */}

          </div>
          </div>
            </IonCol>
            <IonCol>
            <div className="leftalign-nofloat line-height-big organizerAddedEvent">
          <b>{organizerName}</b> has added {(['a','i','o','u','e'].indexOf(eventTypeStr.toLowerCase()[0]) > -1? "an " : "a ") + eventTypeStr}
          </div>
          

        <IonButton className={eventGoing? "eventAttendingButton greenButton eventAttendingButtonGoing" : "eventAttendingButton pinkButton"} 
        onClick={()=>{go(eventId); }} disabled={eventGoing? true : false}>{eventGoing? "GOING" : "GO"}</IonButton>
          <div className="leftalign-nofloat line-height-big stretchDiv">
            <span className="pinkLabel smallLabel">{getEventDateStr(eventStart + "")}</span>
            <br/>
            <b>{eventName}</b>
            <br/>
            <span className="smallGreyLabel">{eventLocation}</span>
          </div>
            <div className="line-above leftalign-nofloat eventDesc">
              <div className={"mediumGreyLabel" + (/[א-ת].+/.test(eventAbout + "")? " rtl":"")}><span className="preLine">{eventAbout}</span></div>
            </div>
          <br/>
            </IonCol>
          </IonRow>
        </IonGrid>
          
        </div>
    )
    
  );
};

export default EventContainer;
