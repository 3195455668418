import { IonButton, IonCol, IonGrid, IonItem, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import React  from 'react';
import {isIOS, isAndroid, isMobileSafari, isChrome, isChromium, isEdge, isEdgeChromium, isFirefox, isOpera, isSafari} from 'react-device-detect';
import './MPContainer.css';

interface ContainerProps { }

const WebFooterContainer: React.FC<ContainerProps> = () => {

  return (
    <div className="footerContainer">
        {/* <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}
    <div className="WebFooter">
    <div style={{backgroundImage: "url(assets/icon/footer-box-h.png)", backgroundRepeat: "repeat-x", height: 16}}>
&nbsp;
            </div>
        <IonGrid className="centered footerGrid">
            <IonRow>
                <IonCol className="footerTopCol">
                    <b className="footerBoldLabel">Create your own WhoToGoWith event</b> 
                <IonButton fill="outline" color="light">Get Started</IonButton>
                </IonCol>
            </IonRow>
            <IonRow className="footerSecondRow">
                <IonCol className="alignLeft">
        <b>Your Account</b>
        <br/>
        Sign Up
        <br/>
        Log In
        <br/>
        Help
                </IonCol>
                <IonCol className="alignLeft">
        <b>Discover</b>
        <br/>
        Events
        <br/>
        Topics
        <br/>
        Cities
        <br/>
        <br/>
                </IonCol>
                <IonCol className="alignLeft">
        <b>Who To Go With</b>
        <br/>
        About
        <br/>
        Blog
        <br/>
        Apps
        <br/>
                </IonCol>
                <IonCol className="alignRight">
                    <IonSelect value="en" className="footerLanguageSelect">
                        <IonSelectOption value="en">English</IonSelectOption>
                    </IonSelect>
                    <br/>
                    <div className="appDownloadIcons">
                    <a href="https://apps.apple.com/us/app/who-to-go-with/id1534771055" target="_blank"><img src="assets/icon/applestore.png" className="apppleStoreIcon"/></a> 
                    {isIOS? ("") : (
                    <a href="https://play.google.com/store/apps/details?id=com.whotogowith" target="_blank"><img src="assets/icon/googleplay.png" className="googlePlayIcon"/></a>
                    )}
                    </div>
                    
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="alignLeft">
                <br/>
                <br/>
                © 2021 WhoToGoWith &nbsp; · &nbsp; All rights reserved &nbsp; · &nbsp; 
                <a href="http://www.whotogowith.com/privacy" target="_blank" className="footerWhiteLink">Terms of Service</a>&nbsp; · &nbsp;
                <a href="http://www.whotogowith.com/privacy" target="_blank" className="footerWhiteLink">Privacy Policy</a>&nbsp; · &nbsp;
                <a href="http://www.whotogowith.com/privacy" target="_blank" className="footerWhiteLink">Cookie Policy</a>
                </IonCol>
            </IonRow>
        </IonGrid>

        
    </div>
    </div>
  );
};


export default WebFooterContainer;
