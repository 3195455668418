import { IonContent, IonHeader, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useState } from 'react';
import {isIOS, isAndroid, isMobileSafari, isChrome, isChromium, isEdge, isEdgeChromium, isFirefox, isOpera, isSafari} from 'react-device-detect';
import SignUpContainer from '../components/SignUpContainer';
import ExitContainer from '../components/ExitContainer';
import WebFooterContainer from '../components/WebFooter';
import MobileWebFooterContainer from '../components/MobileWebFooter';

let initted = false;

const SignUp: React.FC = () => {

  const [wideScreenMode, setWideScreenMode] = useState(false);

  const init = async () => {
    if (!initted) {
      initted = true;
      
      if (window.innerWidth > 800) {
        setWideScreenMode(true);
      }
    }
  }
  init();
  
  return (
    <IonPage>
      <IonHeader>
        {/* <IonToolbar>
          <IonTitle>Blankw</IonTitle>
        </IonToolbar> */}
      </IonHeader>
      <IonContent>
        <IonGrid className="mainContainerForFooter">
          <IonRow>
          <IonCol className="sideCol" size="1"></IonCol>
            <IonCol className="mainCol" size="10">
              <div className="mainDiv">
              <SignUpContainer/>
              <ExitContainer></ExitContainer>
              </div>
            </IonCol>
            <IonCol className="sideCol" size="1"></IonCol>
          </IonRow>
        </IonGrid>
        {wideScreenMode? (
        <WebFooterContainer></WebFooterContainer>
        ): ((isMobileSafari || isChrome || isChromium || isEdge || isEdgeChromium || isFirefox || isOpera || isSafari) && (isIOS || isAndroid))? (
          <MobileWebFooterContainer></MobileWebFooterContainer>
          ):""}
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
