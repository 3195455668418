import React, { useState }  from 'react';
import { IonButton, IonItem, IonThumbnail } from '@ionic/react';
import { IonInput, IonLabel } from '@ionic/react';
import { useForm } from "react-hook-form";
import { Plugins } from '@capacitor/core';
import {host} from '../App.config.json';
import {getEventDateStr} from './utils';
import FaceBookSignInUpContainer from './FaceBookSignInUpContainer';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';

import './MPContainer.css';


interface ContainerProps { }

let initted = false;

const SignUpContainer: React.FC<ContainerProps> = () => {

  const { Storage } = Plugins;

  const [event, setEvent] = useState<any>();

  const init = async () => {
    if (!initted) {
      initted = true;

      loglevelServerSend(log,{});

      let helloGoToEvent = await Storage.get({key:"helloGoToEvent"});
      if (helloGoToEvent && helloGoToEvent.value) {
        fetch(host + "/getEvent", {
          method: 'POST', headers: {
            'Content-Type': 'application/json'}, body: JSON.stringify({token: "guest", eventId: helloGoToEvent.value})})
            .then(res => res.json())
            .then(
              (result) => {
                var event = result.eventDetails;
                if (event) {
                  setEvent(event);
                }
              },
              (error) => {
                log.error("error getEvent (" + helloGoToEvent.value + "): " + error);
              }
            ); // -fetch
      }


    }
  }
  
  init();

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data : any) => {
    fetch(host + "/signup", {
    method: 'POST', headers: {
      'Content-Type': 'application/json'}, body: JSON.stringify({name: data.name, email: data.email, /*phone: data.phone,*/ password: data.password})})
      .then(res => res.json())
      .then(
        (result) => {
          if ("exists" == result.status) {
            Storage.set({key:"userAlreadyExists", value: result.email}); 
            document.location.href = "/SignIn";
          } else if ("success" == result.status) {
            Storage.set({key:"userDetails", value: JSON.stringify(result.userDetails)});
            document.location.href = "/Setup";
          }
        },
        (error) => {
          //log.error("error: " + error);
          alert(error);
        }
      );

  };
  

  return (
    <div className={"mpcontainer" + (isIOS? " iosPaddingTop" : "")}>
     
     <form onSubmit={handleSubmit(onSubmit)}>
       <br/>
     <img src="assets/icon/logo_002.png" className="logoBig"/>
     <br/><br/>
      {/* <IonLabel>Name</IonLabel> */}
      <IonInput color="dark" name="name" placeholder="Name" maxlength={50} ref={register({ required: {value: true, message: 'Name is required'}, pattern: {value: /^[A-Za-z ]+$/i, message: 'Only letters are allowed.'} })} />
      <IonLabel color="warning">{errors.name && errors.name.message}</IonLabel>
      <br/>
      {/* <IonLabel>Email</IonLabel> */}
      <IonInput color="dark" name="email" placeholder="Email" type="email" maxlength={50} ref={register({ required: {value: true, message: 'Email is required'}, pattern: {value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/, message: 'Email not valid.'} })} />
      <IonLabel color="warning">{errors.email && errors.email.message}</IonLabel>
      <br/>
      {/* <IonLabel>Password</IonLabel> */}
      <IonInput color="dark" name="password" placeholder="Password" type="password" maxlength={20} ref={register({ required: {value: true, message: 'Password is required'}, minLength: {value: 6, message: 'Password too short.'} })} />
      <IonLabel color="warning">{errors.password && errors.password.message}</IonLabel>
      {/* <br/> */}
      {/* <IonLabel>Phone</IonLabel> */}
      {/* <IonInput name="phone" placeholder="Phone" type="tel" maxlength={50} ref={register({ minLength: {value: 7, message: 'Phone too short'}, pattern: {value: /^[0-9\+\- ]+$/, message: 'Only numbers are allowed.'} })} />
      <IonLabel color="warning">{errors.phone && errors.phone.message}</IonLabel> */}
      
        

      <br/><br/>      
      <IonButton size="large" id="saveButton" href="Setup" type="submit"className="pinkButton">Sign Up</IonButton>
      <br></br><br></br>
      <IonButton href="signin" className="pinkLabel" fill="clear">Sign in</IonButton>
      
      {event == null? (
        <IonButton href="hello" className="pinkLabel" fill="clear">Find event</IonButton>
      ) : (
      <div className="centered centeredHello" hidden={event == null}>
        <br/>
        <div className="helloEventDiv">
      <div className="myEvent myEventHello">
          <IonThumbnail className="myEventPhoto">
            <img src={"https://gowith.s3.us-east-2.amazonaws.com/events/" + event.id + "_" + event.pictures[0] + ".jpeg"} className="myEventPhoto"/>
          </IonThumbnail>
            <br/>
            <div className="myEventTltle">{event.eventName}</div>
            <br/>
            <div className="eventDate">{getEventDateStr(event.start, true)}</div>
        </div>
        <div className="myEvent myEventHello verticalMiddle verticalMiddleHello">
          <div className="verticalMiddle ">
          <div  className="greyButton greyButtonHello cursorHand" onClick={()=>{document.location.href="hello";}}>CHANGE EVENT</div>
          </div>
        </div>
        </div>
      </div>
      )}
      <br/><br/>

<FaceBookSignInUpContainer></FaceBookSignInUpContainer>

      <br/><br/>
      </form>
    </div>
    
  );
};


export default SignUpContainer;
