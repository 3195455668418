import { IonContent, IonHeader, IonPage, IonToolbar, IonButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useState } from 'react';
//import ExploreContainer from '../components/ExploreContainer';
import ChatsContainer from '../components/ChatsContainer';
import ExitContainer from '../components/ExitContainer';
import WebFooterContainer from '../components/WebFooter';
import {isIOS, isAndroid, isMobileSafari, isChrome, isChromium, isEdge, isEdgeChromium, isFirefox, isOpera, isSafari} from 'react-device-detect';
import MobileWebFooterContainer from '../components/MobileWebFooter';

let initted = false;

const Chats: React.FC = () => {

  const [wideScreenMode, setWideScreenMode] = useState(false);

  const [unreadMessages, setUnreadMessages] = useState(false);
  const unreadMsgs = (unread: boolean) => {
    setUnreadMessages(unread);
  }

  const init = async () => {
    if (!initted) {
      initted = true;
      
      if (window.innerWidth > 800) {
        setWideScreenMode(true);
      }
      
      (window as any).unreadMsgsListeners.push(unreadMsgs);

    }
  }
  init();
  

  

  return (
    <IonPage>
      <IonHeader className="myheader">
        <IonToolbar>
        <img className="topicon" height={36} src={"assets/icon/" + (wideScreenMode? "logo_004.png" : "logo-notext-s.png")} style={{float: "left", paddingLeft: wideScreenMode? "4%" : 12, marginTop: 2, marginRight: wideScreenMode? -132:0}} />
            <IonButton href="/Profile">
            {wideScreenMode? (
                <img className="topicon topicon-web" src="assets/icon/profile-web.svg" />
              ) : (
                <IonIcon className="topicon" src="assets/icon/profile.svg" />
              )}
            </IonButton>
            <IonButton href="/Events">
            {wideScreenMode? (
                <img className="topicon topicon-web" src={"assets/icon/events-web.svg"} />
              ) : (
                <IonIcon className="topicon" src="assets/icon/events.svg" />
              )}
            </IonButton>
            {/* <IonButton href="/Swipe">
              <IonIcon slot="icon-only" src="assets/icon/swipe.svg" />
            </IonButton> */}
            <IonButton>
            {wideScreenMode? (
                <img className="topicon topicon-web" src={unreadMessages? "assets/icon/chat-on-unread-web.svg" : "assets/icon/chat-on-web.svg"} />
              ) : (
                <IonIcon slot="icon-only" src={unreadMessages? "assets/icon/chat-on-unread.svg" : "assets/icon/chat.svg"} />
              )}
            </IonButton>
        </IonToolbar>
    </IonHeader>

    <IonContent className="mycontent">
      {wideScreenMode? (
        <div>
        <IonGrid className="mainContainerForFooter">
          <IonRow>
          <IonCol className="sideCol" size="1"></IonCol>
            <IonCol className="mainCol" size="10">
              <div className="mainDiv">
                <ChatsContainer/>
                <ExitContainer></ExitContainer>
              </div>
            </IonCol>
             <IonCol className="sideCol" size="1"></IonCol>
          </IonRow>
        </IonGrid>

        <WebFooterContainer></WebFooterContainer>
        </div>
                ):(
                  <div>
                    <div style={{minHeight: "74vh"}}>
                  <ChatsContainer/>
                  <ExitContainer></ExitContainer>
                  </div>
                  
                  {(isMobileSafari || isChrome || isChromium || isEdge || isEdgeChromium || isFirefox || isOpera || isSafari) && (isIOS || isAndroid)? (
          <MobileWebFooterContainer></MobileWebFooterContainer>
          ):""}
                </div>
                )}
        
      </IonContent>
    </IonPage>
  );
};

export default Chats;
